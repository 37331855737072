import { OMNI, OMNI_TCP_BICYCLE, OMNI_TCP_BICYCLE_SAMPLE_LOCK, OMNI_TCP_E_BIKE, OMNI_TCP_SCOOTER } from './iotCommands/OMNI';
import { ZIMO } from './iotCommands/ZIMO';
import { ZK_SCOOTER } from './iotCommands/ZK';

import { CORUSCATEIOT } from './iotCommands/CORUSCATEIOT';

import { TELTONIKA, TELTONIKA_FMB920, TELTONIKA_TFT100, TELTONIKA_TST100, TELTONIKA_TST100_FIT_RIDER } from './iotCommands/TELTONIKA';
import { BL10_BICYCLE } from './iotCommands/BL10';

import { NINEBOT_SCOOTER_EU, NINEBOT_SCOOTER_US, NINEBOT_TCP_SCOOTER } from './iotCommands/NINEBOT';
import { FITRIDER_SCOOTER } from './iotCommands/FITRIDER';
import { URBANE_SCOOTER } from './iotCommands/URBANE';

import { ITRIANGLE_SCOOTER } from './iotCommands/ITRIANGLE';

import { CELLOCATOR_TCP_SCOOTER } from './iotCommands/CELLOCATOR';
import { TXED_TCP_PADDLE_BIKE } from './iotCommands/TXED';

import { GOGOBIKE_TCP_BICYCLE } from './iotCommands/GOGOBIKE';

export const IOT_COMMANDS = {
    OMNI: OMNI,
    OMNI_TCP_BICYCLE: OMNI_TCP_BICYCLE,
    OMNI_TCP_BICYCLE_SAMPLE_LOCK: OMNI_TCP_BICYCLE_SAMPLE_LOCK,
    OMNI_TCP_E_BIKE: OMNI_TCP_E_BIKE,
    OMNI_TCP_SCOOTER: OMNI_TCP_SCOOTER,
    ZIMO: ZIMO,
    ZK_SCOOTER: ZK_SCOOTER,
    CORUSCATEIOT: CORUSCATEIOT,
    TELTONIKA: TELTONIKA,
    TELTONIKA_TST100: TELTONIKA_TST100,
    TELTONIKA_TST100_FIT_RIDER: TELTONIKA_TST100_FIT_RIDER,
    TELTONIKA_TFT100: TELTONIKA_TFT100,
    TELTONIKA_FMB920: TELTONIKA_FMB920,
    BL10_BICYCLE: BL10_BICYCLE,
    NINEBOT_SCOOTER_US: NINEBOT_SCOOTER_US,
    NINEBOT_SCOOTER_EU: NINEBOT_SCOOTER_EU,
    NINEBOT_TCP_SCOOTER: NINEBOT_TCP_SCOOTER,
    FITRIDER_SCOOTER: FITRIDER_SCOOTER,
    URBANE_SCOOTER: URBANE_SCOOTER,
    ITRIANGLE_SCOOTER: ITRIANGLE_SCOOTER,
    CELLOCATOR_TCP_SCOOTER: CELLOCATOR_TCP_SCOOTER,
    TXED_TCP_PADDLE_BIKE: TXED_TCP_PADDLE_BIKE,
    GOGOBIKE_TCP_BICYCLE: GOGOBIKE_TCP_BICYCLE
};
